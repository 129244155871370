
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useEffect } from 'react';
import Head from 'next/head';

export default function IndexPage() {
  useEffect(() => {
    window.location.assign('https://www.lionstep.com/');
  }, []);

  return (
    <div>
      <Head>
        <title>Lionstep Job Board</title>
        <link rel="shortcut icon" href="/static/favicon.ico" sizes="any" />
        <link rel="shortcut icon" href="/static/favicon.svg" />
        <meta name="robots" content="noindex" />
      </Head>

      <main className="">
        <h1>Lionstep Job Board</h1>
      </main>
    </div>
  );
}


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  